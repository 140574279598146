import {useState, useEffect, useMemo} from 'react';
import axios from 'axios';
import './App.css';

function ScoresFixtures({competition}) {
    const options = useMemo(
        () => ({
            method: 'GET',
            url: 'https://7sm9snkmu1.execute-api.eu-west-2.amazonaws.com/prod/fixtures',
            params: {
                searchQuery: competition
            },
        }),
        [competition],
    );
    const [results, setResults] = useState([]);

    useEffect(() => {
        console.log('effect updating');
        // setResults({club1:"someclub", club1Score:"club1Score",club2:"someclub2", club2Score:"club2Score"})
        axios.request(options)
            .then((response) => {
                setResults(response.data);
                console.log('Received data: ' + response.data);
            })
            .catch((error) => {
                console.log("Error received whilst querying api: " + error);
            })
            .finally(() => {
                console.log("Request Done!")
            });
    }, [options]);
    return (
        <>
            <div className="div-1">
                {console.log("this is the results object: " + results)}
                {results ? results
                        .sort((a, b) => {
                            console.log(results)
                            return new Date(a.dateTime) - new Date(b.dateTime);
                        })
                        .map((result, index) => (
                            <div key={index} style={{margin: 'auto', width: '100%'}}>
                                <ul style={{margin: 'auto', width: '100%', padding: '0'}}>
                                    <div style={{
                                        margin: 'auto',
                                        width: '33%',
                                        textAlign: 'left',
                                        display: 'inline-block'
                                    }}>{result.club1}</div>
                                    {result.club1Score ?
                                        <div
                                            style={{
                                                margin: 'auto',
                                                width: '33%',
                                                textAlign: 'center',
                                                display: 'inline-block'
                                            }}>
                                            {result.isLive ?
                                                <div style={{backgroundColor: 'lightblue', borderRadius: '12px'}}>
                                                    {result.club1Score + ' - ' + result.club2Score}
                                                </div> :
                                                <div style={{backgroundColor: 'lightgrey', borderRadius: '12px'}}>
                                                    {result.club1Score + ' - ' + result.club2Score}
                                                </div>
                                            }
                                        </div>
                                        :
                                        <div style={{
                                            margin: 'auto',
                                            width: '33%',
                                            textAlign: 'center',
                                            display: 'inline-block'
                                        }}>{result.dateTime}</div>
                                    }
                                    <div style={{
                                        margin: 'auto',
                                        width: '33%',
                                        textAlign: 'right',
                                        display: 'inline-block'
                                    }}>{result.club2}</div>
                                </ul>
                                <div style={{margin: 'left', width: '49%', display: 'inline-block'}}>
                                    {result.goals ?
                                        result.goals.filter((goal) => goal.teamId === 'A').map((goal, index2) => (
                                            <div key={index2} style={{margin: 'auto', width: '100%'}}>
                                                <div style={{
                                                    margin: 'auto',
                                                    width: '100%',
                                                    textAlign: 'right',
                                                    display: 'inline-block',
                                                    fontSize: '14px',
                                                    fontStyle: 'italic',
                                                }}>{goal.playerName}</div>
                                            </div>
                                        ))
                                        : null
                                    }
                                </div>
                                <div style={{margin: 'right', width: '2%', display: 'inline-block'}}/>
                                <div style={{margin: 'right', width: '49%', display: 'inline-block'}}>
                                    {result.goals ?
                                        result.goals.filter((goal) => goal.teamId === 'B').map((goal, index3) => (
                                            <div key={index3} style={{margin: 'auto', width: '100%'}}>
                                                <div style={{
                                                    margin: 'auto',
                                                    width: '100%',
                                                    textAlign: 'left',
                                                    display: 'inline-block',
                                                    fontSize: '14px',
                                                    fontStyle: 'italic',
                                                }}>{goal.playerName}</div>
                                            </div>
                                        ))
                                        : null
                                    }
                                </div>
                            </div>
                        ))
                    : null}
            </div>
        </>
    );
}

function App() {
    const [competition, setCompetition] = useState("English Premier League");

    return (
        <>
            <div>
                <div style={{margin: 'auto', width: '100%', textAlign: 'center'}}>
                    <label>
                        Competition:
                    </label>
                    {' '}
                    <input
                        defaultValue={competition}
                        onKeyUp={(e) => {
                            if(e.key === 'Enter') {
                                setCompetition(e.target.value)
                            }
                        }
                        }
                    />

                </div>
                {competition ? <ScoresFixtures competition={competition}/> : <div/>}
            </div>
        </>
    );
}

export default App;
